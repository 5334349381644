import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { ReUseHOneTag } from './ReUseHOneTag'
import { ReUsePtag } from './ReUsePtag'
import styled from 'styled-components'
import { LinkHubspot } from './LinkHubspot'
// import { Button } from './Button'

const AppRainsignGraphicBannerWapper = styled.div`
  h2 {
    /* color: rgb(15, 33, 55); */
    color: #047cc2;
  }
  p {
    /* color: rgba(52, 61, 72, 0.8); */
    color: #071741;
  }
  ul {
    /* color: rgba(52, 61, 72, 0.8); */
    color: #071741;
    font-size: 1.2rem;
  }
  .Video-Banner-Section {
    overflow: hidden;
    /* margin-top: 21px; */
    width: 33vw;
    height: auto;
    margin-right: 60px;
    /* border: 2px solid #34a1d5; */
    /* border-radius: 12px; */
    /* @media (max-width: 1090px) {
      height: 250px;
    }
    @media (max-width: 608px) {
      height: 150px;
    } */
    @media (max-width: 2560px) {
      height: 575px;
    }
    @media (max-width: 2460px) {
      height: 555px;
    }
    @media (max-width: 2360px) {
      height: 530px;
    }
    @media (max-width: 2260px) {
      height: 510px;
    }
    @media (max-width: 2160px) {
      height: 585px;
    }
    @media (max-width: 2060px) {
      height: 580px;
    }
    @media (max-width: 1920px) {
      height: 535px;
    }

    @media (max-width: 1800px) {
      width: 33vw;
      height: 410px;
    }
    @media (max-width: 1700px) {
      width: 33vw;
      height: 386px;
    }
    @media (max-width: 1650px) {
      width: 33vw;
      height: 370px;
    }
    @media (max-width: 1440px) {
      width: 33vw;
      height: 424px;
    }
    @media (max-width: 1024px) {
      width: 50vw;
      height: 430px;
    }
    @media (max-width: 768px) {
      width: 50vw;
      height: 350px;
    }
    @media (max-width: 425px) {
      width: 80vw;
      height: 270px;
    }
  }
`

export const AppRainsignGraphicBanner = (props: any) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <AppRainsignGraphicBannerWapper>
      <motion.div
        className={props.containSection}
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 25 },
        }}
        transition={{ ease: 'easeOut', duration: 1.25, delay: 0.35 }}
      >
        <div className={props.EventBannerSectionOne}>
          <ReUseHOneTag HeadingStyle={props.EventBannerHeadingSize} Heading={props.EventBannerHeading} />
          <ReUsePtag para={props.paraOne} paraStyle={props.EventBannerParaOne} />
          <ReUsePtag para={props.paraTwo} paraStyle={props.EventBannerPara} />
          {/* <ReUsePtag para={props.paraThree} paraStyle={props.EventBannerPara} /> */}
          <div className={props.ButtonStyle}>
            <LinkHubspot to={props.InternalPageShiftUrl} className={props.LinkPathStyle}>
              <span className="sr-only">Jump to packages</span>
              {/* <Button cta={props.ButtonContent} label={props.ButtonContentLabel} type="submit" className="demobutton" /> */}
            </LinkHubspot>
          </div>
        </div>
        {/* <div className={props.EventBannerSectionTwo}>
          <video className={props.EventBannerImage} autoPlay controls muted loop src={props.ImagePath} />
        </div> */}
        <div>
          <img
            src={props.ImagePath}
            className="Video-Banner-Section"
            // frameborder="0"
            // allow="autoplay; fullscreen overflow:hidden"
            // allowfullscreen
            // style="overflow:hidden"
          />
        </div>
      </motion.div>
    </AppRainsignGraphicBannerWapper>
  )
}
